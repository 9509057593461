export default [
    'FE',
    'DFL',
    'MP-SP',
    'MP',
    'MP/TL',
    'CI ONLY',
    'MP-CI ONLY',
    'REC'
]
