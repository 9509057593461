<template>
    <div class="q-lead-counts-chart">
        <QPieChart v-if="showChart" :data="value" />

        <v-simple-table v-if="showDetails" dense style="background: transparent; ">
            <tbody>
                <tr
                    v-for="(status_count, key) in value"
                    :key="key"
                    class="pointer"
                    @click="$emit('row-click', status_count.name)"
                >
                    <td>{{ status_count.name }}</td>
                    <td class="text-right">{{ status_count.y }}</td>
                    <td class="text-right">{{ formatPercentage(status_count.y / totalCount) }}</td>
                </tr>
            </tbody>
        </v-simple-table>
    </div>
</template>

<script>
import QPieChartGeneric from '@/components/charts/QPieChartGeneric.vue';

export default {
    name: 'QLeadCountsChart',

    components: {
        QPieChart: QPieChartGeneric,
    },

    props: {
        value: { type: Array, required: true },
        showDetails: { type: Boolean, default: true },
        showChart: { type: Boolean, default: true },
        totalCount: { type: Number, default: 0 },
    },
};
</script>
