<template>
    <div>
        <v-simple-table dense class="lead-order-line-table">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th v-for="h in the_allocation_headers" :k="h" :class="alignClass(h)">
                            <span v-html="h.text"></span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="allocation in allocations">
                        <td v-for="h in the_allocation_headers" :key="h.value" :class="alignClass(h)">
                            <div v-if="h.value == 'AllocationDate'">
                                {{ formatDateTime(allocation['AllocationDate'])}}
                            </div>
                            <div v-else-if="h.value == 'County'">
                                {{ allocation.LeadOrderLine.County }}
                            </div>
                            <div v-else-if="h.value == 'State'">
                                {{ allocation.LeadOrderLine.State }}
                            </div>
                            <div v-else v-html="allocation[h.value]"></div>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>
<script>
export default {
    name: "QLeadAllocationTable",
    props: ['allocations'],
    data: function() { // data inter
        return {

        };
    },
    computed: {
        the_allocation_headers: function() {
            return [{
                    text: 'Date',
                    value: 'AllocationDate',
                    align: "left"
                }, {
                    text: 'By',
                    value: 'LastChangeBy',
                    align: "left"
                }, {
                    text: 'Status',
                    value: 'AllocationStatus',
                    align: "left"
                }, {
                    text: 'Type',
                    value: 'AllocationType',
                    align: "left"
                }, {
                    text: 'State',
                    value: 'State',
                    align: "left"
                }, {
                    text: 'County',
                    value: 'County',
                    align: "left"
                }, {
                    text: 'LeadLevel',
                    value: 'LeadLevel',
                    align: "center"
                }, {
                    text: 'Qty',
                    value: 'Quantity',
                    align: "center"
                },
                /*{
                               text: 'ApprovedBy',
                               value: 'ApprovedBy',
                               align: "center"
                           }, {
                               text: 'ApprovedDate',
                               value: 'ApprovedDate',
                           }*/
            ]
        },
    },
    methods: {
        alignClass: function(header) {
            if (header.align == "center") {
                return "text-middle"
            }
            if (header.align == "left") {
                return "text-start text-center"
            }
            if (header.align == "right") {
                return "text-end"
            }
        },
    }
}

</script>
