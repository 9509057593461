<template>
    <div class="q-expand-card" :height="height" :color="color">
        <div class="q-expand-card__activator" @click="isExpandedComputed = !isExpandedComputed">
            <slot name="activator" v-bind="{ isExpandable }"></slot>
        </div>

        <div
            class="q-expand-card__content"
            :class="{ 'q-expand-card__content--expanded': isExpandedComputed }"
        >
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isExpandable: { type: Boolean, default: true },
        expanded: { type: Boolean, default: undefined },

        color: { type: String, default: 'white' },
        height: { type: String, default: '100%' },
    },

    data() {
        return { isExpanded: false };
    },

    computed: {
        isExpandedComputed: {
            get() {
                if (!this.isExpandable) { return true; }
                return this.expanded === undefined ? this.isExpanded : this.expanded;
            },
            set(val) {
                this.isExpanded = val;
                this.$emit('update:expanded', val);
            },
        },
    },
};
</script>

<style lang="scss">

.q-expand-card {
  &__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.15s ease-out;

    &--expanded {
      max-height: 10000px;
      transition: max-height 0.25s ease-in;
    }
  }
}
</style>
