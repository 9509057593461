<template>
    <highcharts :options="chartOptions"></highcharts>
    <!--<pie-chart :height="null" :width="null" :chart-data="chartdata" :options="options"></pie-chart>-->
</template>
<script>
import { Chart } from 'highcharts-vue'
import colors from './ChartColors';
//import mockdata from '@/store/sample_data/MockDataHighcharts'
import theme from './Theme'

export default {
    name: "QPieChartGeneric",
    props: ['data', 'title', 'subtitle', 'name', 'format'], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        var g = this;
        return {
            chartOptions: {
                chart: {
                    type: 'pie'
                },
                title: {
                    text: this.title
                },
                subtitle: {
                    text: this.subtitle
                },

                accessibility: {
                    announceNewData: {
                        enabled: true
                    },
                    point: {
                        valueSuffix: '%'
                    }
                },

                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: true,
                            format: '{point.name}: {point.percentage:.1f}%',
                            formatter: function(point_label, options) {
                                return this.key + ' : ' + g.formatCurrency(this.y) + " ({point.y:.1f}%)"
                            }
                        }
                    }
                },

                tooltip: {
                    formatter: function(point_label, options) {
                        return this.key + ' : ' + g.formatCurrency(this.y)
                    }
                    //headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                    //pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
                },

                series: [{
                    name: "Carriers",
                    colorByPoint: true,
                    data: this.data
                }],

            }
        }
    },
    mounted: function() {
        //this.renderChart(this.chartData, this.options)
    },
    computed: {

    },
    methods: {
        updateChartData: function(data) {
            this.chartOptions.series[0].data = data
        }
    },
    watch: {
        'data': function(newV) {
            this.updateChartData(newV)
        }
    },
    components: {
        highcharts: Chart
    }
}
</script>
<style scoped>
</style>