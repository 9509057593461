<template>
    <div>
        <v-card flat id="lead_order_datatable">
            <QDataTableHeader color="q_leads_1">
                <template #left>
                    <p class="q-display-3 pb-0 pt-0 mt-0 mb-0 white--text text-left">
                        <v-btn dark icon small @click="expand_all = !expand_all">
                            <v-icon v-if="expand_all">fas fa-angle-double-up</v-icon>
                            <v-icon v-else>fas fa-angle-double-down</v-icon>
                        </v-btn>
                        {{title}}
                        <v-btn dark icon @click="refresh">
                            <v-icon>fas fa-redo-alt</v-icon>
                        </v-btn>
                    </p>
                </template>
                <template #right>
                    <v-spacer></v-spacer>
                    <v-text-field dense dark v-model="search" :disabled="isDemoMode" append-icon="icon-q-search" label="Search" single-line hide-details></v-text-field>
                </template>
            </QDataTableHeader>
            <v-row>
                <v-col cols="12">
                    <q-filters dark ref="filters" v-model="filters" :available-filters="available_filters" :keys="filter_keys" :show-reset="true">
                    </q-filters>
                </v-col>
            </v-row>
            <v-row class="ml-5 mb-3" v-if="false">
                <v-btn text small color="q_leads_4" @click="exportResults">
                    <v-icon small left>fas fa-file-export</v-icon> Export Results
                </v-btn>
            </v-row>
            <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
            <QExpandableDataTable :dense="hasRole(['SuperAdmin', 'Exec', 'Staff'])" v-on:current-items="updateCurrentItems" :search="search" v-model="selected" :headers="the_headers" :items="all_data" :visibleHeaders="visibleHeaders" item-key="ID" class="elevation-0 lead-order-table" :footer-props="{itemsPerPageOptions:[50, 100, 200, 300]}" :loading="data_loading" :options.sync="options" :expanded.sync="expanded" show-expand v-on:item-expanded="maybeLoadOrderLines" color="q_leads_1">
                <template v-slot:item.OrderDate="{ item }">
                    <span class="nowrap">
                        {{ formatDate(item.OrderDate)}}
                    </span>
                </template>
                <template v-slot:item.OrderNumber="{ item }">
                    <span :class="$vuetify.breakpoint.smAndDown ? 'q-break-word' : 'nowrap'">
                        {{ item.OrderNumber }}
                    </span>
                </template>
                <template v-slot:item.ID="{ item }">
                    <span class="nowrap">
                        <v-btn x-small @click="viewHistoryLog(item)">Log</v-btn>
                    </span>
                </template>
                <template v-slot:item.SwitchboardConfig="{ item }">
                    <v-tooltip top v-if="item.SwitchboardConfig != null">
                        <template v-slot:activator=" { on, attrs }">
                            <v-icon color="green" small>fas fa-check</v-icon>
                        </template>
                        <span>Switchboard Connected</span>
                    </v-tooltip>
                </template>
                <template v-slot:item.AgentName="{ item }">
                    <span class="nowrap">
                        <router-link :to="'/agents/' + item.AgentCode">{{item.AgentName}}</router-link>
                        <v-icon right :x-small="true" v-if="item.AgencyOrderInd">fas fa-building</v-icon>
                        <v-tooltip top>
                            <template v-slot:activator=" { on, attrs }">
                                <v-icon color="yellow" right :x-small="true" v-if="item.AgentStatus != 'Active'" v-on="on">fas fa-exclamation-triangle</v-icon>
                                </v-btn>
                            </template>
                            <span>Status : {{item.AgentStatus}}</span>
                        </v-tooltip>
                    </span>
                </template>
                <template v-slot:item.OrderStatus="{ item }">
                    <div>
                        {{item.OrderStatus}}
                        <v-tooltip top v-if="item.Fulfilled">
                            <template v-slot:activator=" { on, attrs }">
                                <v-icon color="green" small>fas fa-check</v-icon>
                            </template>
                            <span>Fulfilled</span>
                        </v-tooltip>
                    </div>
                </template>
                <template v-slot:item.Approve="{ item }">
                    <div class="nowrap">
                        <v-tooltip top>
                            <template v-slot:activator=" { on, attrs }">
                                <v-btn icon color="primary" v-if="editable(item) && hasRole(['SuperAdmin', 'Exec', 'Staff'])" @click="editOrder(item)" v-on="on">
                                    <v-icon>fas fa-edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Edit Order</span>
                        </v-tooltip>
                        <v-btn color="primary" small v-if="hasStatusChange(item)" @click="updateStatus(item)">Save</v-btn>
                        <span class="nowrap" v-else-if="item.OrderStatus=='New'">
                            <v-tooltip top>
                                <template v-slot:activator=" { on, attrs }">
                                    <v-btn color="primary" icon @click="approveStatus(item)" v-on="on">
                                        <v-icon>fas fa-check-circle</v-icon>
                                    </v-btn>
                                </template>
                                <span>Approve Order</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator=" { on, attrs }">
                                    <v-btn icon color="red" v-if="item.OrderStatus" @click="confirmDeclineOrder(item)" v-on="on">
                                        <v-icon>far fa-times-circle</v-icon>
                                    </v-btn>
                                </template>
                                <span>Decline</span>
                            </v-tooltip>
                        </span>
                        <span class="nowrap" v-else>
                            <v-tooltip top v-if="editable(item) && !hasRole(['SuperAdmin', 'Exec', 'Staff'])" >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon color="primary" @click="editOrder(item)" v-on="on">
                                        <v-icon>fas fa-edit</v-icon>
                                    </v-btn>
                                </template>
                                <span>Edit Order</span>
                            </v-tooltip>
                            <v-tooltip top v-if="pausable(item)">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click="pauseOrder(item)" v-on="on">
                                        <v-icon>fas fa-pause-circle</v-icon>
                                    </v-btn>
                                </template>
                                <span>Pause Allocations</span>
                            </v-tooltip>
                            <v-tooltip top v-if="playable(item)">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon  @click="unpauseOrder(item)" v-on="on">
                                        <v-icon>fas fa-play-circle</v-icon>
                                    </v-btn>
                                </template>
                                <span>Enable Allocations</span>
                            </v-tooltip>
                        </span>
                        <v-tooltip top>
                            <template v-slot:activator=" { on, attrs }">
                                <v-btn icon v-if="reopenable(item) && hasRole(['SuperAdmin', 'Exec', 'Staff'])" @click="reopenOrder(item)" v-on="on">
                                    <v-icon>fas fa-retweet</v-icon>
                                </v-btn>
                            </template>
                            <span>Re-Open Order</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator=" { on, attrs }">
                                <v-btn icon color="red" v-if="cancelable(item)" @click="confirmCancelOrder(item)" v-on="on">
                                    <v-icon>fas fa-times-circle</v-icon>
                                </v-btn>
                            </template>
                            <span>Cancel Order</span>
                        </v-tooltip>
                    </div>
                </template>
                <template v-slot:item.Modify="{ item }">
                    <v-tooltip top v-if="editable(item) && !hasRole(['SuperAdmin', 'Exec', 'Staff'])" >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon color="primary" @click="editOrder(item)" v-on="on">
                                <v-icon>fas fa-edit</v-icon>
                            </v-btn>
                        </template>
                        <span>Edit Order</span>
                    </v-tooltip>
                    <v-tooltip top v-if="pausable(item)">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon  @click="pauseOrder(item)" v-on="on">
                                <v-icon>fas fa-pause-circle</v-icon>
                            </v-btn>
                        </template>
                        <span>Pause Allocations</span>
                    </v-tooltip>
                    <v-tooltip top v-if="playable(item)">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon  @click="unpauseOrder(item)" v-on="on">
                                <v-icon>fas fa-play-circle</v-icon>
                            </v-btn>
                        </template>
                        <span>Enable Allocations</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator=" { on, attrs }">
                            <v-btn icon color="red" v-if="cancelable(item)" @click="confirmCancelOrder(item)" v-on="on">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                        </template>
                        <span>Cancel Order</span>
                    </v-tooltip>
                </template>
                <template v-slot:action=" { item }">
                    <!-- 
                    This is not allowing the circle plus allocation icon for manually allocating. It also doesn't add the Check mark for fulfilled orders. 
                    <QExpandableDataTable :headers="the_order_line_headers" :visible-headers="orderVisibleHeaders" :items="getLeadOrderLines(item)" item-key="ID">
                    </QExpandableDataTable>//-->
                    <div>
                        <v-simple-table dense class="lead-order-line-table">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th v-for="h in the_order_line_headers" :key="h.text" :class="alignClass(h)" :width="(1/the_order_line_headers*100) + '%'">
                                            <span v-html="h.text"></span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="line_item in getLeadOrderLines(item)" :key="line_item.value" :class="line_item.OrderLineStatus.toLowerCase()">
                                        <td v-for="h in the_order_line_headers" :key="h.value" :class="alignClass(h)">
                                            <div v-if="h.value == 'QtyFilledDetails'" :class="alignClass(h) + ' lead-order-line-cell'">
                                                <table dense>
                                                    <tr v-for="(qty, key) in line_item[h.value]" :key="key">
                                                        <td>{{key}}:</td>
                                                        <td>{{qty}}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div v-else-if="h.value == 'QtyFilled'">
                                                {{ line_item.QtyFilled }}
                                                <v-icon color="green" v-if="line_item.OrderLineStatus == 'Complete'" right small>fas fa-check</v-icon>
                                                <v-tooltip top v-else-if="hasPermission(['manage:LeadOrders'])">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon @click="showAllocationPopup(line_item)" v-on="on">
                                                            <v-icon small>fas fa-plus-circle</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Adjust Allocation</span>
                                                </v-tooltip>
                                            </div>
                                            <div v-else-if="h.value == 'OrderDate'">
                                                {{ formatDate(line_item['OrderDate'])}}
                                            </div>
                                            <div v-else-if="h.value == 'ExpirationDate'">
                                                {{ formatDate(line_item['ExpirationDate'])}}
                                            </div>
                                            <div v-else-if="h.value == 'OrderLineStatus'">
                                                {{ line_item.OrderLineStatus }}
                                                <v-icon color="green" v-if="line_item.OrderLineStatus == 'Complete'" right small>fas fa-check</v-icon>
                                                <v-icon color="green" v-else-if="line_item['Fulfilled']" right small>fas fa-check</v-icon>
                                            </div>
                                            <span v-else v-html="line_item[h.value]"></span>
                                        </td>
                                        <td>&nbsp;</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                </template>
                <template slot="body.append">
                    <tr v-if="noActions !== true">
                        <th class="title text-center" v-for="(header,ix) in visibleHeaders" :key="ix">
                            <span v-if="header.value == 'OrderDate'">
                                <v-btn v-if="all_data.length > 0" color="secondary" text @click="exportResults">
                                    <v-icon left>fas fa-download</v-icon>
                                    <template v-if="$vuetify.breakpoint.mdAndUp">
                                        Download
                                    </template>
                                </v-btn>
                            </span>
                            <span v-else-if="header.value == 'QtyRequested'">
                                {{ sumField('QtyRequested') }}
                            </span>
                            <span v-else-if="header.value == 'QtyFilled'">
                                {{ sumField('QtyFilled') }}
                            </span>
                            <span v-else-if="header.value == 'OrderNumber'">
                                Total: {{ all_data.length }}
                            </span>
                        </th>
                    </tr>
                </template>
            </QExpandableDataTable>
        </v-card>
        <v-dialog top tile centered elevation="24" :timeout="null" color="warning" v-model="show_cancel_confirmation" width="50%">
            <v-card class="pa-6 text-center lead-order-table" min-height="">
                <h4>Are you sure you want to cancel this order?</h4>
                <p>If you need to place this order again, it will have to be approved by leads.</p>
                <v-btn color="primary" @click="cancelOrder()">
                    Yes
                </v-btn>
                <v-btn text @click="show_cancel_confirmation = false">
                    No
                </v-btn>
            </v-card>
        </v-dialog>
        <v-dialog top tile centered elevation="24" :timeout="null" color="warning" v-model="show_decline_confirmation" width="50%">
            <v-card class="pa-6 text-center lead-order-table" min-height="">
                <h4>Decline Order</h4>
                <p>You can add a note to the agent when they are notifed via email.</p>
                <v-textarea v-model="cancel_order.Note" label="Note"></v-textarea>
                <v-btn color="red" @click="declineOrder()">
                    Decline
                </v-btn>
                <v-btn text @click="show_decline_confirmation = false">
                    Cancel
                </v-btn>
            </v-card>
        </v-dialog>
        <v-dialog top tile centered elevation="24" :timeout="null" color="warning" v-model="show_allocation_popup" width="50%">
            <v-card class="pa-6 text-center lead-order-table" min-height="">
                <v-progress-linear indeterminate v-if="saving_allocation"></v-progress-linear>
                <h4>Add Allocation</h4>
                <v-row wrap>
                    <v-col cols="6">
                        <v-text-field v-if="show_allocation_popup" autofocus ref="AllocationQty" label="Allocation Quantity" v-model="current_allocation.Qty" type="number" :rules="[form_rules.number, form_rules.required]" @keydown.enter="saveAllocation()"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <q-date-picker v-model="current_allocation.AllocationDate" label="Allocation Date" :allow_time="true"></q-date-picker>
                    </v-col>
                </v-row>
                <v-btn color="primary" @click="saveAllocation()">
                    Save
                </v-btn>
                <v-btn text @click="show_allocation_popup = false">
                    Cancel
                </v-btn>
            </v-card>
        </v-dialog>
        <v-dialog v-model="allocation_popup" width="75%">
            <v-card class="pa-6 text-center lead-order-table" min-height="">
                <v-progress-linear indeterminate v-if="loading_allocation"></v-progress-linear>
                <div v-else>
                    <H3>Allocation History</H3>
                    {{currentOrder.OrderNumber}} - {{currentOrder.AgentName}}
                    <q-lead-allocation-table :allocations="allocationHistory"></q-lead-allocation-table>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog v-model="orderhistory_popup" width="75%">
            <v-card class="pa-6 text-center lead-order-table" min-height="">
                <v-progress-linear indeterminate v-if="loading_history"></v-progress-linear>
                <div v-else>
                    <H3>Order History</H3>
                    {{currentOrder.OrderNumber}} - {{currentOrder.AgentName}}
                    <q-lead-order-history-table :order-history="orderHistory" :order-number="currentOrder.OrderNumber"></q-lead-order-history-table>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
import state_options from '@/store/StateOptions.js'
import product_type_options from '@/store/ProductTypeOptions.js'
import QFilters from '@/components/utils/QFilters.vue'
import QDatePicker from '@/components/utils/QDatePicker.vue'
import LeadOrder from '@/store/Models/LeadOrders/LeadOrder'
import LeadOrderLine from '@/store/Models/LeadOrders/LeadOrderLine'
import LeadAllocation from '@/store/Models/LeadOrders/LeadAllocation'
import LeadQuantity from '@/store/Models/LeadOrders/LeadQuantity'

import QLeadAllocationTable from '@/components/datatables/LeadOrders/QLeadAllocationTable.vue'
import QLeadOrderHistoryTable from '@/components/datatables/LeadOrders/QLeadOrderHistoryTable.vue'
import QDataTableHeader from '../base/QDataTableHeader.vue'
import QExpandableDataTable from '../QExpandableDataTable.vue'

import moment from 'moment'

const { Parser } = require('json2csv');

export default {
    name: "QDxLeadOrderDataTable",
    props: ['agentCode', 'noActions', 'leadTypes', 'title'],
    data: function() { // data internal to component (not available outside)
        return {
            entity: 'lead_order',
            //use this to "preset" a filter
            filters: {
                'LeadType': this.leadTypes != null ? this.leadTypes[0] : "DX",
                "OrderType": "-All-",
                "State": "-All-",
                //"County": "-All-",
                'ProductType': '-All-',
                "OrderStatus": ["Open", "New", 'Requires Balance'],
                "OrderLineStatus": ["Open", "Complete"],
                "OrderFulfillment": "-All-",
                "OrderLineFulfillment": "-All-"
            }, //these are the filters the user has set or you can preset them
            options: {
                'sortBy': ['OrderDate'], //used to set the default sorting column
                'sortDesc': [false],
            },
            expanded: [],
            expand_all: false,
            available_lead_order_filters: null,
            the_data: [], //this will hole the data fromt he server now that we aren't using vuex anymore.
            search: null,
            selected: [],
            allocation_popup: false,
            orderhistory_popup: false,
            loading: false,
            allocationHistory: [],
            currentOrder: {},
            orderHistory: [],
            loading_history: false,
            loading_allocation: false,
            show_cancel_confirmation: false,
            show_decline_confirmation: false,
            cancel_order: {},
            show_allocation_popup: false,
            current_allocation: {
                Qty: null,
                AllocationDate: null,
                AllocationType: "DX"
            },
            saving_allocation: false
        }
    },
    mounted: function() {
        //this.$refs.filters.show = true
        this.refreshData()
    },
    computed: {
        data_loading: function() {
            return this.$store.state.entities.lead_order.loading || this.$store.state.entities.lead_order_line.loading || this.$store.state.entities.lead_allocation.loading || this.$store.state.entities.lead_quantity.loading;
        },
        all_data: function() {
            if (this.data_loading) {
                return []
            }
            var g = this
            var d_query = LeadOrder.query();
            if (g.filters.LeadType != "-All-") {
                d_query.where('LeadType', g.filters.LeadType);
            }
            if (g.filters.State != "-All-") {
                d_query.whereHas('lead_order_lines', (query) => {
                    query.where('State', g.filters.State)
                        //additional filter hides orders that don't have an open line item for the state with the selected filters for LineOrderStatus
                        .where('OrderLineStatus', function(status) {
                            return g.filters.OrderLineStatus.indexOf(status) > -1;
                        });
                })
            }
            if (g.filters.ProductType != "-All-") {
                d_query.whereHas('lead_order_lines', (query) => {
                    query.where('ProductType', g.filters.ProductType)
                });
            }
			if (g.filters.OrderStatus?.length) {
				d_query.where('OrderStatus', (status) => {
					return g.filters.OrderStatus.includes(status)
				})
			}
            if (this.hasRole(['SuperAdmin', 'Exec', 'Staff'])) {
                if (g.filters.OrderLineFulfillment != "-All-") {
                    d_query.whereHas('lead_order_lines', (query) => {
                        if (g.filters.OrderLineFulfillment == "Fulfilled") {
                            query.where('Fulfilled', true)
                        } else {
                            query.where('Fulfilled', false)
                        }
                    });
                }
                if (g.filters.OrderFulfillment != "-All-") {
                    if (g.filters.OrderFulfillment == "Fulfilled") {
                        d_query.where('Fulfilled', true)
                    } else {
                        d_query.where('Fulfilled', false)
                    }
                }
            }
            return d_query.with(['lead_order_lines', 'lead_quantities', 'lead_order_lines.lead_allocations', 'lead_order_lines.lead_allocations.lead_quantities', 'lead_order_lines.lead_quantities']).get();
        },
        filter_keys: function() {
            var f = ['State', 'LeadType', 'OrderType', 'ProductType', 'OrderStatus']; //this must sync up with availableFilters in the Model in Laravel.
            if (this.hasRole(['SuperAdmin', 'Exec', 'Staff'])) {
                f.push("OrderLineStatus")
                f.push("OrderFulfillment")
                f.push("OrderLineFulfillment")
            }
            return f;
        },
        total_items: function() {
            return this.total_num;
        },
        status_items: function() {
            return ['Open', 'Draft', 'Complete', 'Canceled', 'Pending Cancellation', 'New', 'Paused by Agent', 'Paused by Admin', 'Order Declined', 'In Review', 'Requires Balance'];
        },
        fulfillment_items: function() {
            return ['Fulfilled', 'Unfulfilled'];
        },
        line_status_items: function() {
            return ["Open", "Complete", 'Canceled', 'Revised']
        },
        the_headers: function() {
            var r = [{
                text: 'Order Date',
                value: 'OrderDate',
                align: "left"
            }, {
                text: 'Order #',
                value: 'OrderNumber',
                align: "left"
            }, {
                text: 'Agent',
                value: 'AgentName',
                align: "left"
            }, {
                text: 'OptID',
                value: 'OptID',
                align: "left"
            }, {
                text: 'Switchboard',
                value: 'SwitchboardConfig',
                align: "left"
            },{
                text: 'Order Type',
                value: 'OrderType',
                align: "left"
            }, {
                text: 'Status',
                value: 'OrderStatus',
                align: "left"
            }, {
                text: 'Requested',
                value: 'QtyRequested',
                align: "center"
            }]

            if (this.hasRole(['SuperAdmin', 'Exec', 'Staff'])) {
                r.push({
                    text: 'Filled',
                    value: 'QtyFilled',
                    align: "center"
                })
                if (this.noActions !== true) {
                    r.push({
                        text: 'Approve',
                        value: 'Approve',
                        align: "center"
                    })
                    r.push({
                        text: 'Log',
                        value: 'ID',
                    })
                }
            } else if (this.noActions !== true) {
                r.push({
                    text: '',
                    value: 'Modify',
                    align: "center"
                })
            }

            return r;
        },

        visibleHeaders() {
            if (this.$vuetify.breakpoint.xs) {
                return this.the_headers.slice(0, 2)
            }

            if (this.$vuetify.breakpoint.smAndDown) {
                return this.the_headers.slice(0, 3)
            }

            if (this.$vuetify.breakpoint.mdAndDown) {
                return this.the_headers.slice(0, 4)
            }

            return this.the_headers
        },

        orderVisibleHeaders() {
            if (this.$vuetify.breakpoint.xs) {
                return this.the_order_line_headers.slice(0, 2)
            }

            if (this.$vuetify.breakpoint.smAndDown) {
                return this.the_order_line_headers.slice(0, 3)
            }

            if (this.$vuetify.breakpoint.mdAndDown) {
                return this.the_order_line_headers.slice(0, 4)
            }

            return this.the_order_line_headers
        },

        the_order_line_headers: function() {
            var r = [{
                text: 'Order Date',
                value: 'OrderDate',
                align: "left"
            }, {
                text: 'Status',
                value: 'OrderLineStatus',
                align: "center"
            }, {
                text: 'Expiration',
                value: 'ExpirationDate',
                align: "left"
            }, {
                text: 'Product',
                value: 'ProductType',
                align: "left"
            }, {
                text: 'State',
                value: 'State',
                align: "left"
            }, {
                text: 'County',
                value: 'County',
                align: "left"
            }, {
                text: 'Requested',
                value: 'QtyRequested',
                align: "center"
            }]

            if (this.hasRole(['SuperAdmin', 'Exec', 'Staff']) && this.noActions !== true) {
                r.push({
                    text: 'Filled',
                    value: 'QtyFilled',
                    align: "center"
                })
                //r.push({
                //    value: 'QtyFilledDetails',
                //    align: "center"
                //})
            }
            return r;
        },

        available_filters: function() {
            return {
                "State": [...["-All-"], ...state_options],
                //"County": [...["-All-"], ...this.county_options],
                "OrderType": ["-All-", "Standing Order", "One-time Order"],
                "LeadType": this.leadTypes != null ? this.leadTypes : ["DX"],
                "OrderStatus": this.status_items,
                "OrderLineStatus": this.line_status_items,
                "ProductType": [...["-All-"], ...product_type_options],
                "OrderFulfillment": [...["-All-"], ...this.fulfillment_items],
                "OrderLineFulfillment": [...["-All-"], ...this.fulfillment_items]
            };
        },
        //find all the unique lead levels for this data set.
        all_lead_levels: function() {
            var levels = []
            for (var n = 0; n < this.the_data.length; n++) {
                if (typeof this.the_data[n].LeadOrderLines == 'undefined') {
                    continue;
                }
                for (var i = 0; i < this.the_data[n].LeadOrderLines.length; i++) {
                    Object.keys(this.the_data[n].LeadOrderLines[i].QtyFilledDetails).forEach(key => {
                        if (levels.indexOf(key) == -1) {
                            levels.push(key)
                        }
                    });
                }
            }
            return levels;
        },
        county_options: function() {
            if (this.filters.State != "-All-") {
                var lines = LeadOrderLine.query().where("State", this.filters.State).get();
            } else {
                var lines = LeadOrderLine.all();
            }
            var counties = [];
            lines.forEach(function(l) {
                if (counties.indexOf(l.County) == -1) {
                    counties.push(l.County);
                }
            })
            return counties.sort();
        },
        export_fields: function() {
            return ['ID',
                'OrderNumber',
                'QtyRequested',
                'QtyFilled',
                'LeadOrderType',
                'LeadOrderDate',
                'AgentName',
                'AgentCode',
                'OptID',
                //'BaseShopOwnerAgentCode',
                //'BaseShopOwnerOptID',
                //'CreateDate',
                //'Priority',
                'OrderLineDate',
                'OrderLineStatus',
                'LeadOrderStatus',
                'LeadType',
                'ProductType',
                'State',
                'County',
                'ExpirationDate',
                'Canceled',
                'CanceledDate',
                'AgencyOrderInd'
            ]
        }
    },
    methods: {
        loadData: debounce(function(opt) {
            var filters = {
                OrderType: this.filters.OrderType,
                LeadType: this.filters.LeadType,
                AgentCode: this.agentCode,
                OrderStatus: this.filters.OrderStatus.join(',')
            }
            QuilityAPI.getLeadOrderData(this.agentCode, filters);
        }, 200),

        viewOrder: function(item) {
            //this.$router.push({ name: 'LeadOrderDetail', params: { lead_order_id: item.ID } })
        },
        exportResults: function() {
            this.is_exporting = true
            this.refreshData();
        },
        refresh: function() {
            this.expanded = []
            this.expand_all = false
            this.the_data = []
            this.refreshData();
        },
        refreshData: function(search) {
            var opt = {
                ...this.options,
            }
            this.expanded = [];
            this.loadData(opt);
        },
        updateCurrentItems: function(items) {
            this.currentItems = items;
        },
        maybeLoadOrderLines: function(params) {

        },
        splitLeadLevelsHeaders: function(v) {
            return Object.keys(v).join("<span class='lead-divider'>|</span>");
        },
        splitLeadLevels: function(v) {
            var qtys = []
            Object.keys(v).forEach(key => {
                qtys.push(v[key])
            });
            return qtys.join("<span class='lead-divider'>|</span>");
        },
        alignClass: function(header) {
            if (header.align == "center") {
                return "text-middle text-center"
            }
            if (header.align == "left") {
                return "text-start text-center"
            }
            if (header.align == "right") {
                return "text-end"
            }
        },
        viewAllocationLog: function(order) {
            this.allocation_popup = true;
            this.loadAllocationHistory(order)
        },
        loadAllocationHistory(order) {
            var g = this
            g.loading_allocation = true
            g.allocationHistory = []
            g.currentOrder = order
            var f = {
                //"LeadType": this.filters.LeadType,
                //"OrderType": this.filters.OrderType
            }
            QuilityAPI.getLeadOrderAllocations(order.ID, f).then(function(json) {
                g.loading_allocation = false;
                if (typeof json.response != 'undefined' && json.response.clientError) {
                    g.showError(json.response.body.message);
                    return
                }
                g.$set(g, "allocationHistory", json.data);
            }).catch(function(err) {
                g.loading_allocation = false;
                g.showError(err.message);
            });
        },
        viewHistoryLog: function(order) {
            this.orderhistory_popup = true;
            this.loadOrderHistory(order);
        },
        loadOrderHistory(order) {
            var g = this
            g.loading_history = true
            g.orderHistory = []
            g.currentOrder = order
            var f = {
                attr: "LeadOrderAllocations,Quantities,AllocationLeadOrderLine,AllocationQuantities",
                itemsPerPage: 5
            }
            QuilityAPI.getLeadOrderHistory(order.ID, f).then(function(json) {
                g.loading_history = false;
                if (typeof json.response != 'undefined' && json.response.clientError) {
                    g.showError(json.response.body.message);
                    return
                }
                g.$set(g, "orderHistory", json.data);
            }).catch(function(err) {
                g.loading_history = false;
                g.showError(err.message);
            });
        },
        exportResults: function() {
            var g = this
            var fields = this.export_fields;
            var opts = { fields };
            var query = LeadOrderLine.query();
            query.has('lead_order')
            if (this.filters.State != "-All-") {
                query.where('State', this.filters.State)
            }
            if (this.filters.ProductType != "-All-") {
                query.where('ProductType', this.filters.ProductType)
            }
            var the_data = query.with(['lead_order', 'lead_order.lead_quantities', 'lead_order_lines.lead_quantities', 'lead_quantities', 'lead_allocations', 'lead_allocations.lead_quantities', 'lead_order.lead_quantities']).get()
            try {
                var parser = new Parser(opts);
                var csv = parser.parse(the_data);
                var fileURL = window.URL.createObjectURL(new Blob([csv]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                var fileName = this.slugify('LeadOrderExport_' + this.formatDate());
                fileLink.setAttribute('download', fileName + ".csv");
                document.body.appendChild(fileLink);
                fileLink.click();
                this.logActivity("DX Lead Order Datatable Export", {})
            } catch (err) {

            }
        },
        pauseOrder: function(item) {
            var g = this
            if (this.hasRole(['SuperAdmin', 'Exec', 'Staff'])) {
                item.NewOrderStatus = "Paused by Admin";
                item.OrderStatus = "Paused by Admin";
            } else {
                item.NewOrderStatus = "Paused by Agent";
                item.OrderStatus = "Paused by Agent";
            }
            this.updateOrder(item).then(function(json) {
                LeadOrder.insert({ data: json.data })
                g.showInfo("It may take up to 48 hours to pause an order. You will be responsible for leads purchased until the order is paused.")
            });
        },
        unpauseOrder: function(item) {
            var g = this
            item.NewOrderStatus = "Open";
            item.OrderStatus = "Open";
            this.updateOrder(item).then(function(json) {
                LeadOrder.insert({ data: json.data })
            });
        },
        editOrder: function(item) {
            this.$emit("editorder", item)
        },
        reopenOrder: function(item) {
            this.$emit("reopenorder", item)
        },
        confirmCancelOrder: function(item) {
            this.show_cancel_confirmation = true;
            this.cancel_order = item;
        },
        confirmDeclineOrder: function(item) {
            this.show_decline_confirmation = true;
            this.cancel_order = item;
        },
        cancelOrder: function() {
            this.cancel_order.NewOrderStatus = "Canceled";
            this.cancel_order.OrderStatus = "Canceled";
            var g = this;
            g.show_cancel_confirmation = false;
            this.updateOrder(this.cancel_order).then(function(json) {
                LeadOrder.insert({ data: json.data })
                g.showInfo("It may take up to 24 hours to cancel an order. You will be responsible for leads purchased until the order is canceled.")
            });

        },
        declineOrder: function() {
            this.cancel_order.NewOrderStatus = "Order Declined";
            this.cancel_order.OrderStatus = "Order Declined";
            var g = this;
            g.show_decline_confirmation = false;
            this.updateOrder(this.cancel_order).then(function(json) {
                LeadOrder.insert({ data: json.data })
                g.showInfo("Order is declined - the agent as been notified.")
            });

        },
        approveStatus: function(item) {
            item.OrderStatus = "Open";
            item.NewOrderStatus = "Open";
            this.updateOrder(item);
        },
        updateStatus: function(item, status) {
            var g = this;
            this.$nextTick(function() {
                item.OrderStatus = item.NewOrderStatus;
                g.updateOrder(item);
            });
        },
        updateOrder: function(item) {
            var g = this;
            this.loading = true;
            return QuilityAPI.updateLeadOrder(item.ID, item).then(function(json) {
                g.loading = false;
                LeadOrder.insert({ data: json.data })
                return (json)
            }).catch(function(error) {
                g.loading = false;
                g.showError(error)
                return (error)
            })
        },
        hasStatusChange: function(item) {
            if (typeof item.NewOrderStatus == 'undefined' || item.NewOrderStatus == null) {
                return false;
            }
            if (item.OrderStatus != item.NewOrderStatus) {
                return true;
            }
            return false;
        },
        sumField(key) {
            return this.all_data.reduce((a, b) => a + (b[key] || 0), 0)
        },
        showAllocationPopup(lead_order_line) {
            this.show_allocation_popup = true;
            this.current_allocation = {
                Qty: 1,
                AllocationDate: moment().format('YYYY-MM-DD LT'),
                AllocationType: "DX",
                LeadOrderLineID: lead_order_line.ID,
                AllocationStatus: "Complete",
                LeadLevel: "DX"
            }
            var g = this
            this.$nextTick(function() {
                g.$refs.AllocationQty.focus();
            })
        },
        saveAllocation() {
            this.saving_allocation = true;
            var g = this
            return QuilityAPI.saveAllocationQty(this.current_allocation).then(function(json) {
                LeadAllocation.insert({ data: json.allocation })
                LeadQuantity.insert({ data: json.quantity })
                LeadOrder.insert({ data: json.lead_order })
                LeadOrderLine.insert({ data: json.lead_order_line })
                g.saving_allocation = false
                g.show_allocation_popup = false;
            }).catch(function(error) {
                g.saving_allocation = false
                g.loading = false;
                g.showError(error)
                return (error)
            })
        },
        clicked(value) {
            this.expanded.push(value)
        },
        getLeadOrderLines(order) {
            var g = this
            if (this.hasRole(["SuperAdmin", "Staff", "Exec"])) {
                var temp_lines = [];
                //use the filters set for admins...
                order.lead_order_lines.forEach(function(l) {
                    if (g.filters.OrderLineStatus.indexOf(l.OrderLineStatus) > -1) {
                        temp_lines.push(l)
                    }
                })
            } else {
                var temp_lines = [];
                //only show open lead order lines to agents. 
                order.lead_order_lines.forEach(function(l) {
                    if (l.OrderLineStatus == 'Open') {
                        temp_lines.push(l)
                    }
                })
            }
            var r = [];
            //now filter for state if the state is filtered...
            if (this.filters.State != '-All-') {
                temp_lines.forEach(function(l) {
                    if (l.State == g.filters.State) {
                        r.push(l)
                    }
                })
            } else {
                r = temp_lines;
            }
            if (this.hasRole(["SuperAdmin", "Staff", "Exec"])) {
                //if we have the lead order line fulfillment filter on we need to filter based on that. 
                if (this.filters.OrderLineFulfillment != '-All-') {
                    temp_lines = r
                    r = []
                    temp_lines.forEach(function(l, ix) {
                        if (g.filters.OrderLineFulfillment == 'Fulfilled' && l.Fulfilled) {
                            r.push(l);
                        }
                        if (g.filters.OrderLineFulfillment == 'Unfulfilled' && !l.Fulfilled) {
                            r.push(l);
                        }
                    })
                }
            }
            return r;
        },
        'editable': function(item) {
            if (!this.hasRole(['SuperAdmin', 'Exec', 'Staff']) && this.user.Agent.Status != 'Active') {
                return false
            }
            var nonEditable = ['Closed', 'Canceled', 'Draft', 'Pending Cancellation'];
            return nonEditable.indexOf(item.OrderStatus) == -1;
        },
        'cancelable': function(item) {
            var nonCancelable = ['Closed', 'Canceled', 'Pending Cancellation', 'Draft', 'New', 'Order Declined'];
            return nonCancelable.indexOf(item.OrderStatus) == -1;
        },
        'playable': function(item) {
            if (this.hasRole(['SuperAdmin', 'Exec', 'Staff'])) {
                var playable = ['Paused by Admin', 'Paused by Agent'];
            } else {
                if (this.user.Agent.Status == 'Active') {
                    var playable = ['Paused by Agent'];
                } else {
                    return false;
                }
            }
            return playable.indexOf(item.OrderStatus) > -1;
        },
        'pausable': function(item) {
            var pauseable = ['Open'];
            return pauseable.indexOf(item.OrderStatus) > -1;
        },
        'reopenable': function(item) {
            if (this.hasRole(['SuperAdmin', 'Exec', 'Staff'])) {
                var reopenable = ['Complete', 'Canceled'];
                return reopenable.indexOf(item.OrderStatus) > -1;
            }
            return false;
        }
    },
    watch: {
        'filters': {
            deep: true,
            handler(newV, oldV) {
                this.options.page = 1
            }
        },
        'filters.LeadType': function(n) {
            var g = this;
            this.$nextTick(g.refreshData)
        },
        'filters.OrderStatus': function(n) {
            var g = this;
            this.$nextTick(g.refreshData)
        },
        'agentCode': function(n) {
            var g = this;
            this.$nextTick(g.refreshData)
        },
        'filters.OrderType': function(n) {
            var g = this;
            this.$nextTick(g.refreshData)
        },
        'filters.State': function(n) {
            //this.filters.County = "-All-";
        },
        'options': {
            deep: true,
            handler(newV) {
                /*var g = this;
                if (!this.firstPull) {
                    this.$vuetify.goTo('#' + this.entity + '_datatable', {
                        duration: 250,
                        easing: 'easeOutCubic',
                    });
                }
                this.$nextTick(g.refreshData)
                */
            }
        },
        'expand_all': function(newV) {
            if (newV) {
                this.expanded = this.all_data
            } else {
                this.expanded = []
            }
        },
    },
    components: {
        QFilters,
        QLeadAllocationTable,
        QLeadOrderHistoryTable,
        QDatePicker,
        QDataTableHeader,
        QExpandableDataTable,
    }
}
</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

.grey_row {
    background-color: #f7f7f7;
}

.lead-order-table {
    font-size: 12px;
}

.lead-order-line-table {
    background-color: #f7f7f7;
}

.lead-order-line-table table td {
    font-size: .9em;
    width: 10%;
}

.lead-order-line-table table tr.canceled td,
.lead-order-line-table table tr.revised td {
    opacity: .5;
    text-decoration: line-through;
}
</style>
<style>
.lead-divider {
    margin: 0 6px;
}
</style>