<template>
    <v-menu ref="menu1" v-model="datepicker" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field :disabled="disabled" v-model="dateFormatted" :label="label" hint="YYYY-MM-DD format" persistent-hint prepend-icon="fas fa-calendar-alt" v-bind="attrs" @click:prepend="showDatePicker" :rules="rules"></v-text-field>
        </template>
        <v-date-picker v-model="date" no-title @input="update"></v-date-picker>
    </v-menu>
</template>
<script>
import moment from 'moment'

export default {
    name: "QDatePicker",
    props: ['value', 'label', 'rules', 'disabled', 'allow_time'], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        return {
            datepicker: false,
            date: this.value,
            dateFormatted: this.value,
        }
    },
    computed: {
        dateText() {
            return this.date
        },
        the_rules() {
            return [
                this.form_rules.date,
                ...rules
            ]
        }
    },
    methods: {
        showDatePicker: function() {
            this.datepicker = true;
            this.date = this.dateFormatted
        },
        update: function(v) {
            if (typeof v == 'undefined') {
                return
            }
            if (v == "") {
                this.$emit('input', null);
                this.dateFormatted = null;
            }
            //check if they have finished typing a full date
            if (v == null || v.length < 10) {
                return
            }
            //check if it's even valid
            if (!moment(v).isValid()) {
                return
            }
            //if all is good then fomrat it the way we need it. 
            if (this.allow_time === true) {
                var d = moment(v).format('YYYY-MM-DD LT');
            } else {
                var d = moment(v).format('YYYY-MM-DD');
            }

            this.$emit('input', d);
            this.dateFormatted = d;
            this.datepicker = false;
        },
    },
    watch: {
        'dateFormatted': function(newV) {
            this.update(newV);
        },
        'value': function(newV, oldV) {
            this.dateFormatted = newV;
        }
    },
}

</script>
<style scoped>
</style>
