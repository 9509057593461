<template>
    <QDynamicSubmenu
        :base-url="baseUrl"
        :default-title="title"
        :default-buttons="buttons"
    />
</template>

<script>
//table data + search
import QDynamicSubmenu from '@/components/navigation/Submenus/base/QDynamicSubmenu.vue'

export default {
    name: "QAgentAccountsAdminSubmenu",
    data() {
        return {
            title: "Agent Accounting",
            baseUrl: '/agents/accounts',
            buttons: [
                {
                    label: "Balances",
                    to: "/agents/accounts",
                    icon: "fas fa-arrow-alt-circle-right",
                },
                {
                    label: "Transactions",
                    to: "/agents/transactions",
                    icon: "fas fa-arrow-alt-circle-right",
                },
                {
                    label: "Reporting",
                    icon: "fas fa-arrow-alt-circle-right",
                    children: [
                        {
                            label: "Lead Sales Summary",
                            to: "/leads/lead-sales-summary",
                            icon: "fas fa-arrow-alt-circle-right",
                        },
                        {
                            label: "Lead Sales Receipts",
                            to: "/leads/lead-sales-receipts",
                            icon: "fas fa-arrow-alt-circle-right",
                        },
                        {
                            label: "Lead Credit Report",
                            to: "/leads/lead-credit-report",
                            icon: "fas fa-arrow-alt-circle-right",
                        }
                    ],
                }
            ],
        }
    },
    components: {
        QDynamicSubmenu,
    },
}
</script>

<style></style>