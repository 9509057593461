<template>
    <v-row wrap v-if="!loading && counts">
        <v-col cols="12" v-if="counts">
            <h3 class="ml-3 pb-0 mb-0">You have {{counts.Count}} Leads</h3>
            <p class="caption ml-3 pt-0 mt-0">
                *Leads assigned in the last 12 months are available. Log into Opt to see a full list.
            </p>
        </v-col>

        <v-col cols="12">
            <QExpand v-if="counts" :isExpandable="$vuetify.breakpoint.mdAndDown" style="width: 100%;">
                <template v-if="$vuetify.breakpoint.mdAndDown" #activator="{ isExpandable }">
                    <v-card-title style="background-color:#360568; color:white;">
                        <strong>Lead Stats</strong>

                        <v-spacer></v-spacer>

                        <v-icon color="white">
                            <template v-if="isExpandable">
                                $expand
                            </template>
                        </v-icon>
                    </v-card-title>
                </template>

                <v-row wrap>
                    <v-col
                        v-for="(chart, index) in computedCharts" :key="chart.title"
                        cols="12" lg="4"
                    >
                        <v-card color="q_leads_5">
                            <v-card-title :id="`q-leads-step-${index + 1}`" style="background-color:#360568; color:white;">
                                <v-icon color="q_leads_3" class="pr-3">fas fa-chart-pie</v-icon>
                                <strong>{{ chart.title }}</strong>
                            </v-card-title>

                            <v-card-text class="pt-4">
                                <QLeadCountsChart
                                    :value="chart.data"
                                    :totalCount="counts.Count"
                                    :showChart="$vuetify.breakpoint.mdAndUp"
                                    @row-click="$emit('updatefilters', ({ [chart.filter]: $event }))"
                                />
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </QExpand>
        </v-col>
    </v-row>
</template>

<script>
import QLeadCountsChart from './QLeadCounts/QLeadCountsChart.vue';
import QExpand from './QLeadCounts/QExpand.vue';

export default {
    name: 'QLeadCounts',

    components: { QLeadCountsChart, QExpand },

    props: {
        loading: { type: Boolean, default: false },
        counts: { type: Object },
    },

    computed: {
        pie_data_lead_level() {
            return this.counts.LeadLevel.map((item) => ({
                name: item.LeadLevel, y: Number(item.total),
            }));
        },

        pie_data_lead_status() {
            return this.counts.LeadStatus.map((item) => ({
                name: item.LeadStatus, y: Number(item.total),
            }));
        },

        pie_data_account_type() {
            return this.counts.LeadType.map((item) => ({
                name: item.LeadType, y: Number(item.total),
            }));
        },

        computedCharts() {
            return [
                { title: 'Lead Level', filter: 'LeadLevel', data: this.pie_data_lead_level },
                { title: 'Status', filter: 'Status', data: this.pie_data_lead_status },
                { title: 'Lead Type', filter: 'LeadType', data: this.pie_data_account_type },
            ];
        },
    },
};
</script>
